import { computed, ref, watch } from '@vue/composition-api';
import moment from 'moment';
// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import store from '@/store';

export default function useReconcile() {
  // Use toast
  const toast = useToast();

  const refPaymentListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'hotkey', label: 'X' },
    { key: 'student.fullName', label: 'Name', stickyColumn: true, variant: 'primary' },
    { key: 'student.email', label: 'Email' },
    { key: 'cohort', label: 'Cohort' },
    { key: 'profile.attributes.fundingSource', label: 'Funding' },

    { key: 'payment.date', label: 'Date' },
    { key: 'payment.amount', label: 'Amount' },
    { key: 'payment.description', label: 'Description' },
    { key: 'status' },
    { key: 'actions' },
  ];

  const perPage = ref(10);
  const totalPayments = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [5, 10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('createdAt');
  const isSortDirDesc = ref(true);

  const statusFilter = ref('');
  const fundingFilter = ref('');

  const dateFilter = ref('');

  const dataMeta = computed(() => {
    const localItemsCount = refPaymentListTable.value ? refPaymentListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPayments.value,
    };
  });

  const refetchData = () => {
    if (refPaymentListTable.value) refPaymentListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, statusFilter, fundingFilter, dateFilter, sortBy, isSortDirDesc], () => {
    refetchData();
  });

  const fetchPayments = (ctx, callback) => {
    if (!searchQuery.value) {
      callback([]);
      totalPayments.value = 0;
      return;
    }

    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,

      populate: 'student,profile,cohort',
    };

    if (dateFilter.value) {
      console.log(`filter date`, dateFilter.value);
      const [start, end] = dateFilter.value;
      if (start) pl.paymentStartDate = start;
      if (end) pl.paymentEndDate = end;
    }

    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }

    if (fundingFilter.value) {
      pl.fundingSource = fundingFilter.value;
    }

    const isInDateRange = (date) => {
      return true;
      // return date >= startDate && date <= endDate;
    };

    store
      .dispatch('app-accounting-receivable/fetchAccounts', pl)
      .then((response) => {
        const { results } = response.data;

        const payments = [];

        for (const account of results) {
          const accPayments = [];

          for (const payment of account.payments) {
            if (!isInDateRange(payment.date)) {
              continue;
            }

            accPayments.push(payment);
          }

          delete account.payments;
          delete account.charges;

          payments.push(
            ...accPayments.map((payment) => {
              return { ...account, payment };
            })
          );
        }

        console.log(`doing payments`, payments);

        callback(payments);
        totalPayments.value = payments.length;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching accounts list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  const markPaymentAsPaid = async (accountId, paymentId, markAsVa = false) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-accounting-receivable/fetchAccount', {
          id: accountId,
          queryParams: { populate: 'student profile cohort' },
        })
        .then(async (response) => {
          const { data: account } = response;

          const payload = {
            payments: account.payments.map((payment) => {
              if (payment._id === paymentId || payment.id === paymentId) {
                payment.status = 'paid';
                payment.paidAt = new Date();
                if (markAsVa) {
                  payment.paidVia = 'ach';
                  payment.paidBy = 'va';
                }
              }

              return payment;
            }),
          };

          store
            .dispatch('app-accounting-receivable/updateAccount', {
              id: accountId,
              accountData: payload,
            })
            .then((response2) => {
              const { data } = response2;
              resolve(data);
            })
            .catch((err) => {
              resolve(err);
            });
        })
        .catch((error) => {
          reject(err);
        });
    });
  };

  const markPaymentAsUnpaid = async (accountId, paymentId) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-accounting-receivable/fetchAccount', {
          id: accountId,
          queryParams: { populate: 'student profile cohort' },
        })
        .then(async (response) => {
          const { data: account } = response;

          const payload = {
            payments: account.payments.map((payment) => {
              if (payment._id === paymentId || payment.id === paymentId) {
                payment.status = 'unpaid';
                payment.paidAt = null;
              }

              return payment;
            }),
          };

          store
            .dispatch('app-accounting-receivable/updateAccount', {
              id: accountId,
              accountData: payload,
            })
            .then((response2) => {
              const { data } = response2;
              resolve(data);
            })
            .catch((err) => {
              resolve(err);
            });
        })
        .catch((error) => {
          reject(err);
        });
    });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const accountStatusOptions = [
    { label: 'Unpaid', value: 'unpaid' },
    { label: 'Partially Paid', value: 'partially_paid' },
    { label: 'Paid', value: 'paid' },
    { label: 'Refunded', value: 'refunded' },
    { label: 'Void', value: 'void' },
    { label: 'Archive', value: 'archived' },
  ];

  const paymentStatusOptions = [
    { label: 'Unpaid', value: 'unpaid' },
    { label: 'Paid', value: 'paid' },
    { label: 'Refunded', value: 'refunded' },
    { label: 'Reversed', value: 'reversed' },
    { label: 'Void', value: 'void' },
  ];

  const fundingSourceOptions = [
    { label: 'Open', value: 'open' },
    { label: 'VRRAP', value: 'vrrap' },
    { label: 'VET-TEC', value: 'vettec' },
    { label: 'WIOA', value: 'wioa' },
    { label: 'GI Bill', value: 'gi_bill' },
    { label: 'Army COOL', value: 'army_cool' },
    { label: 'AirForce COOL', value: 'airforce_cool' },
    { label: 'MYCAA', value: 'mycaaa' },
    { label: 'VR&E', value: 'vrne' },
    { label: 'Corporate', value: 'corp' },
  ];

  const yesNoOptions = [
    { label: 'N/A', value: 'na' },
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
  ];

  const resolveAccountProgramVariant = (status) => {
    if (status === 'open') return 'success';
    if (status === 'vettec') return 'info';
    if (status === 'vrrap') return 'primary';
    if (status === 'wioa') return 'warning';
    return 'secondary';
  };

  return {
    fetchPayments,
    markPaymentAsPaid,
    markPaymentAsUnpaid,

    tableColumns,
    perPage,
    currentPage,
    totalPayments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPaymentListTable,

    refetchData,

    resolveAccountProgramVariant,

    //

    accountStatusOptions,
    paymentStatusOptions,
    statusFilter,
    fundingSourceOptions,
    fundingFilter,
    yesNoOptions,
    dateFilter,
  };
}
